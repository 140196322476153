.form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.text-center {
  text-align: center;
}

.loading {
  padding: 20px;
  text-align: center;
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-weight: 600;
  display: block;
  margin-bottom: 6px;
}

.required {
  color: red;
  margin-left: 4px;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
}

.form-radio-option,
.form-checkbox-option {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
}

.button {
  background-color: #16a34a;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.button:hover {
  background-color: #15803d;
}

.success-message {
  color: green;
  font-weight: bold;
  margin-bottom: 16px;
}

.location-display {
  margin-top: 10px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 4px;
}

.location-display a {
  color: #1a73e8;
  text-decoration: none;
}

.location-display a:hover {
  text-decoration: underline;
}

/* Add to FormFiller.css */
.signature-container {
  margin: 10px 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.signature-actions {
  margin-top: 5px;
}

.signature-notice {
  color: #4caf50;
  font-size: 0.9em;
  margin-top: 5px;
}

canvas {
  cursor: crosshair;
  min-width: 250px;
}

@media (max-width: 480px) {
  .signature-container {
    padding-bottom: 10px;
  }
}
